div#dashboardv2 {
	.border-left-primary {
    	border-left: 0.25rem solid #4e73df !important;
  	}
  
	.border-left-success {
		border-left: 0.25rem solid #1cc88a !important;
	}
	
	.border-left-info {
		border-left: 0.25rem solid #36b9cc !important;
	}
	
	.border-left-warning {
		border-left: 0.25rem solid #f6c23e !important;
	}
	
	.border-left-danger {
		border-left: 0.25rem solid #e74a3b !important;
	}

	.text-xs {
		font-size: .8rem;
	}

	#dashboard-main {
		min-height: calc(100vh - 66px - 66px - 115px) !important; 
		// max-height: calc(100vh - 66px - 66px - 126px) !important; 
		// overflow-x: hidden;
		// overflow-y: auto;
	}

	#dashboard-fullscreen {
		min-height: calc(100vh - 66px - 26px) !important; 
		max-height: calc(100vh - 66px - 26px) !important; 
		overflow-x: hidden;
		overflow-y: auto;
	}

  	.grid-stack-item-content .card-body {
  	  	overflow-y: auto;
  	}

	.highcharts-container  {
		margin: auto;
	}

	.chart-container {
		overflow-x: hidden;
	}

	// card
	.widget .card-header {
		padding: 0.75rem 1.25rem;
		margin-bottom: 0;
		background-color: #f8f9fc !important;
		border-bottom: 1px solid #e3e6f0;
		color: #4e73df !important;
	}

	.fullScreenDashboard {
		// background-color: white;
		background-color: #f6fbfd;
		// margin-top: 66px;
		min-height: 100vh;
		width: 100%;
		position: fixed;
		top: 0;
	}

	.shareDashboard {
		background-color: white;
		margin-top: 66px;
		min-height: 100vh;
		width: 100%;
		position: fixed;
		top: 0;
	}

	.ace_editor {
		// font: inherit !important;
		font-size: 14px;
		width: 95%;
	}
}