@import "ember-cli-bootstrap-4/bootstrap"; 
@import "homepage/style_old";
@import "homepage/style";
@import "login-register/style";
@import "projects/style";
@import "project/style";
@import "project/fileinput";
@import "dashboard/style";
@import "channel/bootstrap-datetimepicker";
@import "channel/style";
@import "settings/style";
@import "dashboardv2/gridstack";
@import "dashboardv2/style";
@import "dashboardv2/fontawesome-iconpicker";
@import "dashboardv2/spectrum";


/* need to fix the problem the navbar overlapping content */
html {
	position: relative;
	min-height: 100%;
	font-family: sans-serif;
	min-height: 100%;
}

body { 
	/* padding-top: 70px; 
	padding-bottom:50px; */
	font-family: sans-serif; /* "Helvetica Neue", Helvetica, Arial, sans-serif; */
	font-size: 14px;
	line-height: 20px;
	height: 100%;
	min-height: 100%;
}

body > .ember-view {
	height: 100%;
}

/* navbar */
.navbar-default {
    background-color: #ff9900; /* #F8F8F8; */
    border-color: #E7E7E7;
}

/* Title */
.navbar-default .navbar-brand {
    color: #FFFFFF;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: #FFFFFF;
}
/* Link */
.navbar-default .navbar-nav > li > a {
    color: #FFFFFF;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #333;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #E7E7E7;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #555;
    background-color: #D5D5D5;
}
/* Caret */
.navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: #777;
    border-bottom-color: #777;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #333;
    border-bottom-color: #333;
}
.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #555;
    border-bottom-color: #555;
}
/* Mobile version */
.navbar-default .navbar-toggle {
    border-color: #DDD;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #DDD;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #CCC;
}
@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #777;
    }
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
          color: #333;
    }
}

/* Footer */
.footer1{
  /* position: absolute; */ /* Sticky Footer */
  bottom: 5rem;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 50px;
  /* background-color: #ff9900; */
}

.footer-default1 {
  position: relative;
  top: 10px;
  color: #FFFFFF;
  font-size: 120%;
}

/* blockly */
@font-face {
	font-family: Consolas;
	src: url(consola.ttf);
}
		
/*
body {
	overflow: hidden;
	padding-top: 70px;
}
*/		

.button {
	padding-left: 20px;
	padding-right: 20px;
	height: 40px;
	font-size: 14pt;
	font-family: Consolas;
	text-align: center;
	line-height: 40px;
	border-radius: 3px;
	font-weight: bold;
	color: #444444;
	background-color: #AAAAEE;
	box-shadow: 2px 2px 2px #888888;
	cursor: pointer;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	margin-left: 15px;
	margin-right: 15px;
}
.button:hover {
	position: relative; 
	top: 1px;
	left: 1px;
	box-shadow: 1px 1px 2px #888888;
}

.button:active {
	background-color: #9999DD;
}
		
#menu {
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 60px;
	background-color: #D0D0D0;
	padding: 9px 20px 0px 20px;
}

#select-board-dialog {
	max-width: 800px;
}

#manage-datasources-dialog {
	min-width: 800px;
}

#blockly-container {
	position: relative;
	width: 100%;
	// height: 800px;
	height: calc(100vh - 180px);
}

#content {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0;
	right: 0;
	border: 5px solid #E8E8E8;
}
		
#editor {
	margin: 0;
	width: 100%;
	height: 100%;
	font-size: 13pt;
}
		
div#tab_blockly {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 40%;
}

svg:not(:root) {
	overflow: visible !important;
}
		
div#tab_text {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 60%;
	right: 0;
	padding-left: 10px;
	background-color: #E8E8E8;
}
		
div#console {
	margin: 0;
	position: absolute;
	height: 100%;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #D0D0D0;
	/* padding: 10px 10px 10px 30px; */
	overflow-x: hidden;
	overflow-y: hidden;
	font-family: Consolas;
	background-color: white;
}
		
div#overlay {
	display: none;
	z-index: 99;
	background-color: rgba(50, 50, 50, .5);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	text-align: center;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

div#overlay_msgbox {
	background-color: #FFF;
	background-color: rgba(255, 255, 255, .8);
	margin-top: 250px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 30px;
	width: 400px;
	height: 90px;
	border-radius: 25px;
	box-shadow: 5px 5px 10px #666666;
	font-size: 14pt;
	font-family: Consolas;
	cursor: default;
}

/* Project Listing */
.overlay-image {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width:100%;
	height: auto;
}

.overlay-projects-image {
	display: block;
	position: relative;
	margin-left: auto;
	margin-right: auto;
	width:70%;
	height: auto;
}

.overlay-title {
	position: absolute;
	font-size: 100%;
	font-size: 1.5vw;
	top: 5px;
	left: 0;
	width: 100%;
}

.overlay-footer {
	position: absolute;
	font-size: 100%;
	font-size: 1.2vw;
	bottom: 10px;
	left: 0;
	width: 100%;
}

/* freeboard */
* {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
  }
  
 *:before,
 *:after {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}
  
 /* Need !important to override the board-conent content-box */
.widget {
	-webkit-box-sizing: border-box !important;
	   -moz-box-sizing: border-box !important;
			box-sizing: border-box !important;
}
  
#board-content, #board-content *,
#pane-template, #pane-template *,
#modal_overlay, #modal_overlay * {
	-webkit-box-sizing: content-box;
	   -moz-box-sizing: content-box;
			box-sizing: content-box;
}
  
  /* override the bootstrap */
  /*
  #modal_overlay .modal {
	  display:block;
	  bottom: unset;
  }
  */
  
#mymodal, #mymodal *, #datasourcesModal, #datasourcesModal *, #editDashboardModal, #editDashboardModal * {
	-webkit-box-sizing: border-box !important;
	   -moz-box-sizing: border-box !important;
			box-sizing: border-box !important;
	color: #000000;
}

#datasourcesModal table th {
	font-size: 20px;
}

#datasourcesModal table td {
	font-size: 18px;
}

#cookiesAlert {
	position: fixed;
    bottom: 15px;
	z-index: 2;
}

.cursor-pointer {
	cursor: pointer;
}

// circle button
.btn-circle {
	width: 35px;
	height:35px;
	line-height: 35px;
	text-align: center;
	padding: 0;
	border-radius: 50% !important;
}
  
.btn-circle i {
	position: relative;
	top: -1px;
	left: -5.4px;
	width: 1.28571429em;
}
  
// save msg
.save-msg-dev {
	font-size: 10px !important;
	color: #e7e2e2dc !important;
    text-decoration: none !important;
	display: flex !important;
}

.save-msg-dev i {
	align-self: center !important;
}

// main-card-body
.main-card-body {
	overflow-y: auto;
}

// modal 
.modal-dialog-lg {
    max-width: 1000px !important;
}

// form
.col-form-label {
	font-weight: 600;
}

// theme color tone
.theme-text {
	color: #2c3d49 !important;
}

.theme-bg-dark {
    background-color: #2c3d49 !important;
    color: white !important;
}

.theme-text-dark {
	color: #2c3d49 !important; 
}

.theme-bg-light {
    background-color: rgba(255, 255, 255, .9) !important;
    color: white !important;
}

.theme-bg-grey {
    background-color: #fbfbfb !important;
}

.theme-bg-blue {
    background-color: #0085c4 !important; 
    color: white !important;
}

.theme-text-blue {
    color: #008cce !important; 
}

.theme-bg-red {
    background-color: #e25566 !important; 
    color: white !important;
}

.theme-text-red {
    color: #e25566 !important; 
}

.theme-bg-yellow {
    background-color: #f5bf28 !important; 
    color: white !important;
}

.theme-text-yellow {
    color: #f5bf28 !important; 
}

.theme-bg-green {
    background-color: #01a78a !important; 
    color: white !important;
}

.theme-text-green {
    color: #01a78a !important; 
}

.nav-link-collapse::after {
    color: white !important;
}


// -----------

.bg-dark {
	background-color: #393e42 !important;
}

// .theme-text-green {
// 	color: #26a69a !important;
// }

// .theme-bg-green {
// 	background-color: #26a69a !important;
// }

// .theme-bg {
// 	background-color: #ffbd28 !important;
// }

// .theme-bg-red {
// 	background-color: #ff6347 !important;
// }

// .theme-text {
// 	color: #ffbd28 !important;
// }

// .theme-btn {
// 	background-color: #ffbd28 !important;
// 	color: white !important;
// }

// .theme-btn-border {
// 	border: solid 1px #ffbd28 !important;
// }

// .theme-btn-outline {
// 	border-color: #ffbd28 !important;
// 	color: #ffbd28 !important;
// }

// .theme-nav-link {
// 	color: #000000 !important;
// }

.theme-nav-link.active {
	// border: 2px solid #ffbd28 !important;
	border-left: 3px solid #ffbd28 !important;
	border-radius: 0 !important;
	background-color: white !important;
	color: black !important;
}

.text-multi-truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
}

/* dropdown submenu */
.dropdown-submenu {
	position: relative;
}
  
.dropdown-submenu a::after {
	transform: rotate(-90deg);
	position: absolute;
	right: 6px;
	top: .8em;
}
  
.dropdown-submenu .dropdown-menu {
	top: 0;
	left: 100%;
	margin-left: .1rem;
	margin-right: .1rem;
}

/* Table */
.table tbody tr td{
	vertical-align: middle;
}

/* modal section title */
.modal-sction-title {
	font-size: 1em;
	font-weight: bold;
}

.modal-sction-title.no-span {
	display: table;
	white-space: nowrap;
	&:before, &:after {
	  border-top: 1px solid #EEE;
	  content: '';
	  display: table-cell;
	  position: relative;
	  top: 0.8em;
	  width: 45%;
	}
	&:before { right: 1.5%; }
	&:after { left: 1.5%; }
}

// fuulscreen loader
/* Absolute Center Spinner */
.loading {
	position: fixed;
	z-index: 99999;
	height: 2em;
	width: 2em;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
  
/* Transparent Overlay */
.loading:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
	background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}
  
/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
	/* hide "loading..." text */
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
  
.loading:not(:required):after {
	content: '';
	display: block;
	font-size: 10px;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	-webkit-animation: spinner 1500ms infinite linear;
	-moz-animation: spinner 1500ms infinite linear;
	-ms-animation: spinner 1500ms infinite linear;
	-o-animation: spinner 1500ms infinite linear;
	animation: spinner 1500ms infinite linear;
	border-radius: 0.5em;
	-webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  	box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}
  
/* Animation */
@-webkit-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}
@-moz-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}
@-o-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}
@keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
}

@import "ember-cli-bootstrap-4/bootstrap";
