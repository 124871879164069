/* CSS for home page */
div#newHomepage,
div#verification, 
div#resetPassword, 
div#termsCondtions, 
div#privacyPolicy,
div#changeLog,
div#order
{   
	/************* cloud homepage **************/	
    body {
		overflow-x: hidden;
		font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	}	

    .banner {
        padding-top: 120px;
        padding-bottom: 80px;
        background-color: #b3e1ff;
        color: #2c3d49;
		// background-image: url("../homepage/banner_background.jpg");
        // background-size: cover; 
        // width: 100%;
        // min-height: 65vh;
	}
	
	.banner-overlay {
		// background-color: rgba(193, 193, 193, 0.9);
	}

	.section {
        padding-top: 40px;
        padding-bottom: 40px;
	}

	.section-title {
         text-align: center;
         margin-top: 20px;
         margin-bottom: 20px;
	}

	.card-video {
		width: 100%;
		height: 250px;
	}

	.logo {
        max-height: 100px;
        min-width: 70%;
        max-width: 70%;
        object-fit: contain;
	}

	.bg-section {
		background-color: #212529;
		background-image: url("../img/map-image.png");
		background-repeat: no-repeat;
		background-position: center;
    }
    
    .footer {
        padding: 25px 0;
        text-align: center;
    }
        
    .footer span.copyright {
        font-size: 90%;
        line-height: 40px;
        text-transform: none;
        font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }
        
    .footer ul.quicklinks {
        font-size: 90%;
        line-height: 40px;
        margin-bottom: 0;
        text-transform: none;
        font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    }
        
    ul.social-buttons {
        margin-bottom: 0;
    }
        
    ul.social-buttons li a {
        font-size: 20px;
        line-height: 50px;
        display: block;
        width: 50px;
        height: 50px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        color: white;
        border-radius: 100%;
        outline: none;
        background-color: #212529;
    }
        
    ul.social-buttons li a:active, ul.social-buttons li a:focus, ul.social-buttons li a:hover {
        background-color: #f7c92c;
	}
	
	.page-section {
		padding: 100px 0;
	}
	  
	.page-section h2.section-heading {
		font-size: 40px;
		margin-top: 0;
		margin-bottom: 35px;
	}
	  
	.page-section h3.section-subheading {
		font-size: 16px;
		font-weight: 400;
		font-style: italic;
		margin-bottom: 75px;
		text-transform: none;
		font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	}

	.pointer {
	    cursor: pointer;
	}

	.feature {
		display: flex;
		align-items: center;
	}

	.feature-icon-div {
		width: auto;
	}

	.feature-icon {
		font-size: 32px;
	}

	.feature-detail {
		padding-left: 15px;
	}

	.order-item {
        padding: 20px;
        background-color: #FFFFFF;
        color: #000000;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        cursor: pointer;
    }

    .order-item.selected {
        background-color: #d88100;
        color: #FFFFFF;
        border: 1px solid #d88100;
    }

    .order-item .icon {
        display: none;
    }

    .order-item.selected .icon {
        display: inline;
        margin-right: 10px;
	}
	
	.scroll-to-top {
		position: fixed;
		right: 15px;
		bottom: 15px;
		display: none;
		width: 50px;
		height: 50px;
		text-align: center;
		color: white;
		background: rgba(52, 58, 64, 0.5);
		line-height: 45px;
		border-radius: 50%;
	}
	
	.scroll-to-top:focus, .scroll-to-top:hover {
		color: white;
	}

	.bg-light-transparent {
		background-color: rgba(248, 249, 250, 0.829) !important;
		color: black;
	}

	.bg-map {
		min-height: 100vh;
		background-color: #212529;
		background-image: url("../img/map-image.png");
		background-position: center;
    }
    
	@media (max-width: 768px) {
        .banner-img {
            padding-top: 50px;
        }
    }


    /************* skyey homepage **************/
    // body {
	// 	overflow-x: hidden;
	// 	font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    // }

    // .pointer {
    //     cursor: pointer;
    // }
    
    // .banner {
    //     color: white;
	// 	background-image: url("../homepage/banner_background.jpg");
    //     background-size: cover; 
    //     height: 95vh;
    // }

    // .sub-background {
    //     background-color: #e8f1f7;
    // }

    // .features-icon {
    //     color: #007bff;
    // }

    // .main-color {
    //     color: #007bff;
    // }

    // .sub-color {
    //     color: #f7941f
    // }

    // .theme-btn {
    //     background-color: #f7941f !important;
    //     color: white !important;
    // }

    // .card-img-top {
    //     width: 100%;
    //     height: 300px;
    // }

    // .img-overlay {
    //     background-color: hsla(209, 85%, 0%, 0.6);
    //     color: white;
    // }
    /************* skyey homepage **************/

	// body {
	// 	overflow-x: hidden;
	// 	font-family: 'Roboto Slab', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// p {
	// 	line-height: 1.75;
	// }
	  
	// a {
	// 	// color: #f7c92c;
	// }
	  
	// a:hover {
	// 	color: #fec503;
	// }
	  
	// .text-primary {
	// 	color: #f7c92c !important;
	// }
	  
	// h1,
	// h2,
	// h3,
	// h4,
	// h5,
	// h6 {
	// 	font-weight: 700;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// .page-section {
	// 	padding: 100px 0;
	// }
	  
	// .page-section h2.section-heading {
	// 	font-size: 40px;
	// 	margin-top: 0;
	// 	margin-bottom: 35px;
	// }
	  
	// .page-section h3.section-subheading {
	// 	font-size: 16px;
	// 	font-weight: 400;
	// 	font-style: italic;
	// 	margin-bottom: 75px;
	// 	text-transform: none;
	// 	font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// @media (min-width: 768px) {
	// 	section {
	// 	  padding: 150px 0;
	// 	}
	// }
	  
	// ::-moz-selection {
	// 	background: #f7c92c;
	// 	text-shadow: none;
	// }
	  
	// ::selection {
	// 	background: #f7c92c;
	// 	text-shadow: none;
	// }
	  
	// img::-moz-selection {
	// 	background: transparent;
	// }
	  
	// img::selection {
	// 	background: transparent;
	// }
	
	// img::-moz-selection {
	// 	background: transparent;
	// }
	  
	// .btn {
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// 	font-weight: 700;
	// }
	  
	// .btn-xl {
	// 	font-size: 18px;
	// 	padding: 20px 40px;
	// }
	  
	// .btn-primary {
	// 	background-color: #f7c92c;
	// 	border-color: #f7c92c;
	// }
	  
	// .btn-primary:active, .btn-primary:focus, .btn-primary:hover {
	// 	background-color: #fec810 !important;
	// 	border-color: #fec810 !important;
	// 	color: white;
	// }
	  
	// .btn-primary:active, .btn-primary:focus {
	// 	-webkit-box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
	// 	box-shadow: 0 0 0 0.2rem rgba(254, 209, 55, 0.5) !important;
	// }

	// .bg-light-transparent {
	// 	background-color: rgba(248, 249, 250, 0.829) !important;
	// 	color: black;
	// }

	// .scroll-to-top {
	// 	position: fixed;
	// 	right: 15px;
	// 	bottom: 15px;
	// 	display: none;
	// 	width: 50px;
	// 	height: 50px;
	// 	text-align: center;
	// 	color: white;
	// 	background: rgba(52, 58, 64, 0.5);
	// 	line-height: 45px;
	// }
	
	// .scroll-to-top:focus, .scroll-to-top:hover {
	// 	color: white;
	// }
	  
	// #mainNav {
	// 	background-color: #212529;
	// }
	  
	// #mainNav .navbar-toggler {
	// 	right: 0;
	// 	padding: 13px;
	// 	text-transform: uppercase;
	// 	color: white;
	// 	border: 0;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// #mainNav .navbar-brand {
	// 	color: #f7c92c;
	// 	/* font-family: 'Kaushan Script', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
	// }
	  
	// #mainNav .navbar-brand.active, #mainNav .navbar-brand:active, #mainNav .navbar-brand:focus, #mainNav .navbar-brand:hover {
	// 	color: #fec503;
	// }
	  
	// #mainNav .navbar-nav .nav-item .nav-link {
	// 	font-size: 90%;
	// 	font-weight: 400;
	// 	padding: 0.75em 0;
	// 	letter-spacing: 1px;
	// 	color: white;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// #mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
	// 	color: #f7c92c;
	// }
	  
	// @media (min-width: 992px) {
	// 	#mainNav {
	// 		padding-top: 25px;
	// 		padding-bottom: 25px;
	// 		-webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
	// 		transition: padding-top 0.3s, padding-bottom 0.3s;
	// 		border: none;
	// 		background-color: transparent;
	// 	}
	// 	#mainNav .navbar-brand {
	// 		font-size: 1.75em;
	// 		-webkit-transition: all 0.3s;
	// 		transition: all 0.3s;
	// 	}
	// 	#mainNav .navbar-nav .nav-item .nav-link {
	// 	  	padding: 1.1em 1em !important;
	// 	}
	// 	#mainNav.navbar-shrink {
	// 		padding-top: 0;
	// 		padding-bottom: 0;
	// 		background-color: #212529;
	// 	}
	// 	#mainNav.navbar-shrink .navbar-brand {
	// 		font-size: 1.25em;
	// 		padding: 12px 0;
	// 	}
	// }

	// header.masthead-special {
	// 	min-height: 100vh;
	// 	background-color: #212529;
	// 	background-image: url("../img/map-image.png");
	// 	// background-repeat: no-repeat;
	// 	background-position: center;
	// }
	  
	// header.masthead {		
	// 	/* text-align: center; */
	// 	color: white;
	// 	position: relative;
	// 	background-image: url("../img/bg-masthead_new.jpg");
	// 	background-repeat: no-repeat;
	// 	background-attachment: scroll;
	// 	background-position: center center;
	// 	background-size: cover;
	// }
	  
	// header.masthead .intro-text {
	// 	padding-top: 150px;
	// 	padding-bottom: 100px;
	// }

	// header.masthead .intro-text .intro-lead-in {
	// 	font-size: 50px;
	// 	/* font-style: italic; */
	// 	line-height: 22px;
	// 	margin-bottom: 25px;
	// 	font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// header.masthead .intro-text .intro-heading {
	// 	font-size: 25px;
	// 	font-weight: 700;
	// 	line-height: 25px;
	// 	margin-bottom: 25px;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// @media (min-width: 768px) {
	// 	header.masthead .intro-text {
	// 		padding-top: 350px;
	// 		padding-bottom: 250px;
	// 	}
	// 	header.masthead .intro-text .intro-lead-in {
	// 		font-size: 50px;
	// 		/* font-style: italic; */
	// 		line-height: 50px;
	// 		margin-bottom: 15px;
	// 		font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// 		/* font-family: 'Droid Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'; */
	// 	}
	// 	header.masthead .intro-text .intro-heading {
	// 		font-size: 25px;
	// 		/* font-weight: 700; */
	// 		line-height: 25px;
	// 		margin-bottom: 15px;
	// 		font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// 	}
	// }
	  
	// .service-heading {
	// 	margin: 15px 0;
	// 	text-transform: none;
	// }
	  
	// section#contact-us {
	// 	background-color: #212529;
	// 	background-image: url("../img/map-image.png");
	// 	background-repeat: no-repeat;
	// 	background-position: center;
	// }
	  
	// section#contact-us .section-heading {
	// 	color: #fff;
	// }
	  
	// section#contact-us .form-group {
	// 	margin-bottom: 25px;
	// }
	  
	// section#contact-us .form-group input,
	// section#contact-us .form-group textarea {
	// 	padding: 20px;
	//   }
	  
	// section#contact-us .form-group input.form-control {
	// 	height: auto;
	// }
	  
	// section#contact-us .form-group textarea.form-control {
	// 	height: 248px;
	// }
	  
	// section#contact-us .form-control:focus {
	// 	border-color: #f7c92c;
	// 	-webkit-box-shadow: none;
	// 	box-shadow: none;
	// }
	  
	// section#contact-us ::-webkit-input-placeholder {
	// 	font-weight: 700;
	// 	color: #ced4da;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// section#contact-us :-moz-placeholder {
	// 	font-weight: 700;
	// 	color: #ced4da;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// section#contact-us ::-moz-placeholder {
	// 	font-weight: 700;
	// 	color: #ced4da;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// section#contact-us :-ms-input-placeholder {
	// 	font-weight: 700;
	// 	color: #ced4da;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }

	// .logo {
	// 	min-height: 120px;
	// 	max-height: 150px;
	// 	min-width: 80%;
	// 	max-width: 80%;
	// 	object-fit: contain;
	// }
	  
	// .footer {
	// 	padding: 25px 0;
	// 	text-align: center;
	// }
	  
	// .footer span.copyright {
	// 	font-size: 90%;
	// 	line-height: 40px;
	// 	text-transform: none;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// .footer ul.quicklinks {
	// 	font-size: 90%;
	// 	line-height: 40px;
	// 	margin-bottom: 0;
	// 	text-transform: none;
	// 	font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	// }
	  
	// ul.social-buttons {
	// 	margin-bottom: 0;
	// }
	  
	// ul.social-buttons li a {
	// 	font-size: 20px;
	// 	line-height: 50px;
	// 	display: block;
	// 	width: 50px;
	// 	height: 50px;
	// 	-webkit-transition: all 0.3s;
	// 	transition: all 0.3s;
	// 	color: white;
	// 	border-radius: 100%;
	// 	outline: none;
	// 	background-color: #212529;
	// }
	  
	// ul.social-buttons li a:active, ul.social-buttons li a:focus, ul.social-buttons li a:hover {
	// 	background-color: #f7c92c;
    // }
    
    // .order-item {
    //     padding: 20px;
    //     background-color: #FFFFFF;
    //     color: #000000;
    //     border: 1px solid #ebebeb;
    //     border-radius: 10px;
    //     cursor: pointer;
    // }

    // .order-item.selected {
    //     background-color: #d88100;
    //     color: #FFFFFF;
    //     border: 1px solid #d88100;
    // }

    // .order-item .icon {
    //     display: none;
    // }

    // .order-item.selected .icon {
    //     display: inline;
    //     margin-right: 10px;
    // }
}