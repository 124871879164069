/*!
 * Start Bootstrap - 3 Col Portfolio (https://startbootstrap.com/template-overviews/3-col-portfolio)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-3-col-portfolio/blob/master/LICENSE)
 */

#projects, #aiymodels {
  height: 100%;
  min-height: 100%;
}

div#projects,
div#aiymodels
{
	#body {
		padding-top: 54px;
		height: 100%
	}

	#body > .container {
		padding-bottom: 5em;
	}

	@media (min-width: 992px) {
		#body {
		padding-top: 56px;
		height: 100%
		}
	}

	.portfolio-item, .aiymodel-item {
		margin-bottom: 30px;
	}

	.pagination {
		margin-bottom: 30px;
	}

	footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		margin-top: -6em;
	}

	.card-img-top {
		background-color: #f7f7f7;
		height: 230px;
		object-fit: contain;
	}

	// pagination
	// .pagination li {
	// 	position: relative;
	// 	display: block;
	// 	padding: 0.5rem 0.75rem;
	// 	margin-left: -1px;
	// 	line-height: 1.25;
	// 	color: #343a40;
	// 	background-color: #fff;
	// 	border: 1px solid #dddfeb;
	// }
	
	// .pagination li:hover,
	// .pagination li.active {
	// 	z-index: 2;
	// 	background-color: #343a40;
	// 	text-decoration: none;
	// 	color: #eaecf4 !important;
	// 	border-color: #343a40;
	// }
	
	// .pagination li:focus {
	// 	z-index: 2;
	// 	outline: 0;
	// 	-webkit-box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
	// 	box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
	// }  

	// .pagination li a {
	// 	color: inherit;
	// 	text-decoration: none;
	// }
}